import node, {
  type graphGeneralSettingsPageQuery,
} from '#gen/relay/graphGeneralSettingsPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/$graph/settings/')({
  staticData: { title: 'Settings' },
  async loader({ context, params }) {
    return context.preload<graphGeneralSettingsPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-general-settings-page'),
    'GraphGeneralSettingsPage',
  ),
})
