import node, {
  type graphEnvironmentVariablesPageQuery,
} from '#gen/relay/graphEnvironmentVariablesPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/settings/environment-variables',
)({
  staticData: { title: 'Environment variables' },
  loader({ context, params }) {
    return context.preload<graphEnvironmentVariablesPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-environment-variables-page'),
    'GraphEnvironmentVariablesPage',
  ),
})
