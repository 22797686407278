/**
 * @generated SignedSource<<75aac56193a467edc6c22bd00fc0e912>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GraphType = "FEDERATED" | "STANDALONE" | "%future added value";
export type federatedSchemaLayoutQuery$variables = {
  account: string;
  branch: string;
  graph: string;
};
export type federatedSchemaLayoutQuery$data = {
  readonly branch: {
    readonly graph: {
      readonly graphType: GraphType | null | undefined;
    };
    readonly subgraphs: ReadonlyArray<{
      readonly name: string;
    }>;
  } | null | undefined;
};
export type federatedSchemaLayoutQuery = {
  response: federatedSchemaLayoutQuery$data;
  variables: federatedSchemaLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "branch"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graphType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Subgraph",
  "kind": "LinkedField",
  "name": "subgraphs",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "federatedSchemaLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Graph",
            "kind": "LinkedField",
            "name": "graph",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "federatedSchemaLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Branch",
        "kind": "LinkedField",
        "name": "branch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Graph",
            "kind": "LinkedField",
            "name": "graph",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bac69a7d33d6842f7a0088c3ff2aced2",
    "id": null,
    "metadata": {},
    "name": "federatedSchemaLayoutQuery",
    "operationKind": "query",
    "text": "query federatedSchemaLayoutQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n) {\n  branch(accountSlug: $account, graphSlug: $graph, name: $branch) {\n    graph {\n      graphType\n      id\n    }\n    subgraphs {\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4dd4a71b0239922df93b270c7e16c5df";

export default node;
