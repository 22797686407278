import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/github-installed')({
  staticData: { title: 'GitHub app installed!' },
  component: lazyLoadedComponent(
    () => import('#app/pages/github-installed-page'),
    'GithubInstalledPage',
  ),
})
