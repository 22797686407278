import { Config } from '#app/config'

export let rudder: Awaited<ReturnType<typeof getSdk>> | undefined

export async function initRudderSdk() {
  if (!Config.RUDDERSTACK_WRITE_KEY || !Config.RUDDERSTACK_DATAPLANE_URL) return

  const sdk = await getSdk()

  sdk.load(Config.RUDDERSTACK_WRITE_KEY, Config.RUDDERSTACK_DATAPLANE_URL)
  sdk.page()
  sdk.identify({ theme: document.documentElement.className })

  rudder = sdk
}

async function getSdk() {
  return await import('rudder-sdk-js' /* webpackChunkName: "rudder-sdk" */)
}
