import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/_personal/settings')({
  staticData: { title: '' },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/personal-settings-layout'),
    'PersonalSettingsLayout',
  ),
})
