import node, {
  type membersPageQuery,
} from '#gen/relay/membersPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/members',
)({
  staticData: { title: 'Members' },
  loader({ context, params }) {
    return context.preload<membersPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/members-page/members-page'),
    'MembersPage',
  ),
})
