import type { githubAuthorizeCallbackCodeMutation } from '#gen/relay/githubAuthorizeCallbackCodeMutation.graphql'
import { authClient } from '#lib/auth/auth-client'
import { redirectToAuth } from '#lib/auth/redirect-to-auth'
import { createRelayEnvironment } from '#lib/relay/relay-environment'
import { createFileRoute } from '@tanstack/react-router'
import { commitMutation, graphql } from 'relay-runtime'

export const Route = createFileRoute('/github-authorize-callback')({
  staticData: { title: '' },
  validateSearch(search) {
    if (!('code' in search)) throw new Error('Missing `code` search param')

    // Why do we have to check for number here? Search params should never be numbers, but
    // tanstack router seems to automatically convert strings that look like
    // numbers to numbers.
    if (typeof search.code === 'number' || typeof search.code === 'string')
      return { code: search.code.toString() }

    throw new Error('Invalid `code` search param')
  },
  loaderDeps({ search }) {
    return { code: search.code }
  },
  async loader({ deps }) {
    function getToken() {
      return authClient.getToken()
    }

    const token = await getToken()

    if (!token) await redirectToAuth()

    if (!token) throw new Error('Failed to authenticate')

    await new Promise<void>((resolve, reject) => {
      commitMutation<githubAuthorizeCallbackCodeMutation>(
        createRelayEnvironment(getToken),
        {
          mutation: graphql`
            mutation githubAuthorizeCallbackCodeMutation(
              $input: GithubUserTokenUpdateInput!
            ) {
              githubUserTokenUpdate(input: $input) {
                __typename
              }
            }
          `,
          variables: {
            input: {
              code: deps.code,
            },
          },
          onCompleted(response, errors) {
            if (errors) reject(errors)
            else resolve()
          },
          onError(error) {
            reject(error)
          },
        },
      )
    })

    if (window.name === 'window-grafbase-github-app') {
      window.close()
    }
  },
})
