import node, {
  type accountAccessTokensPageQuery,
} from '#gen/relay/accountAccessTokensPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/access-tokens',
)({
  staticData: { title: 'Access Tokens' },
  loader({ context, params }) {
    return context.preload<accountAccessTokensPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/account-access-tokens-page'),
    'AccountAccessTokensPage',
  ),
})
