import type { ActionsType } from '#lib/commands-k/types'
import { CustomError } from '#lib/errors/custom-error'
import { captureException } from '@sentry/react'
import { createRouter } from '@tanstack/react-router'

import { routeTree } from '../__generated__/route-tree'
import { ErrorHandler, NotFoundPage } from '../lib/errors/errors'
import { FullpageSpinner } from '../lib/ui/loaders/spinner'

export const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadDelay: 150,

  defaultNotFoundComponent: NotFoundPage,
  defaultErrorComponent: ErrorHandler,
  defaultPendingComponent: FullpageSpinner,

  defaultOnCatch(error, errorInfo) {
    if (error instanceof CustomError && !error.shouldReport) {
      return
    }
    captureException(error)
  },

  context: {},
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
  interface HistoryState {
    triggerCmdkAction?: ActionsType
  }
}
