import node, {
  type graphGitPageQuery,
} from '#gen/relay/graphGitPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/settings/git',
)({
  staticData: { title: 'Git' },
  async loader({ context, params }) {
    return context.preload<graphGitPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-git-page'),
    'GraphGitPage',
  ),
})
