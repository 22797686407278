import node, {
  type graphLayoutQuery,
} from '#gen/relay/graphLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/$graph')({
  staticData: { title: '' },
  loader({ context: { preload }, params: { account } }) {
    return preload<graphLayoutQuery>(node, { account })
  },

  component: lazyLoadedComponent(
    () => import('#app/pages/graph/graph-layout'),
    'GraphLayout',
  ),
})
