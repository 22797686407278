import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/settings/checks',
)({
  staticData: { title: '' },
  component: lazyLoadedComponent(
    () => import('#app/pages/checks/checks-configuration-layout'),
    'ChecksConfigurationLayout',
  ),
})
