import node, {
  type graphSettingsLayoutQuery,
} from '#gen/relay/graphSettingsLayoutQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/$graph/settings')({
  staticData: { title: 'Settings' },
  loader({ context, params }) {
    return context.preload<graphSettingsLayoutQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/settings/graph-settings-layout'),
    'GraphSettingsLayout',
  ),
})
