/**
 * @generated SignedSource<<c8dc9faa064bc5f4582646111a2abd00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type subgraphSchemaPageQuery$variables = {
  account: string;
  branch: string;
  graph: string;
  subgraph: string;
};
export type subgraphSchemaPageQuery$data = {
  readonly subgraph: {
    readonly schema: string;
    readonly updatedAt: string;
    readonly url: string;
  } | null | undefined;
};
export type subgraphSchemaPageQuery = {
  response: subgraphSchemaPageQuery$data;
  variables: subgraphSchemaPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subgraph"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountSlug",
        "variableName": "account"
      },
      {
        "kind": "Variable",
        "name": "branch",
        "variableName": "branch"
      },
      {
        "kind": "Variable",
        "name": "graphSlug",
        "variableName": "graph"
      },
      {
        "kind": "Variable",
        "name": "subgraphName",
        "variableName": "subgraph"
      }
    ],
    "concreteType": "Subgraph",
    "kind": "LinkedField",
    "name": "subgraph",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schema",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "subgraphSchemaPageQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "subgraphSchemaPageQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d71712e6fb645320cf1213a0c65bd48d",
    "id": null,
    "metadata": {},
    "name": "subgraphSchemaPageQuery",
    "operationKind": "query",
    "text": "query subgraphSchemaPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $subgraph: String!\n) {\n  subgraph(accountSlug: $account, graphSlug: $graph, branch: $branch, subgraphName: $subgraph) {\n    url\n    updatedAt\n    schema\n  }\n}\n"
  }
};
})();

(node as any).hash = "028e2ba1fc30de7ae1a22635beb8731d";

export default node;
