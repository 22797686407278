/**
 * @generated SignedSource<<e473e992b2fe07c74cb57b01b9ae6244>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SchemaCheckErrorSeverity = "ERROR" | "WARNING" | "%future added value";
export type checkDetailsPageQuery$variables = {
  id: string;
};
export type checkDetailsPageQuery$data = {
  readonly schemaCheck: {
    readonly compositionCheckErrors: ReadonlyArray<{
      readonly message: string;
      readonly title: string;
    }>;
    readonly createdAt: string;
    readonly errorCount: number;
    readonly id: string;
    readonly lintCheckErrors: ReadonlyArray<{
      readonly message: string;
      readonly severity: SchemaCheckErrorSeverity;
      readonly title: string;
    }>;
    readonly operationCheckErrors: ReadonlyArray<{
      readonly message: string;
      readonly severity: SchemaCheckErrorSeverity;
      readonly title: string;
    }>;
    readonly subgraphName: string | null | undefined;
    readonly validationCheckErrors: ReadonlyArray<{
      readonly message: string;
      readonly title: string;
    }>;
  } | null | undefined;
};
export type checkDetailsPageQuery = {
  response: checkDetailsPageQuery$data;
  variables: checkDetailsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "severity",
    "storageKey": null
  },
  (v1/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "SchemaCheck",
    "kind": "LinkedField",
    "name": "schemaCheck",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subgraphName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errorCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationCheckError",
        "kind": "LinkedField",
        "name": "validationCheckErrors",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompositionCheckError",
        "kind": "LinkedField",
        "name": "compositionCheckErrors",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperationCheckError",
        "kind": "LinkedField",
        "name": "operationCheckErrors",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LintCheckError",
        "kind": "LinkedField",
        "name": "lintCheckErrors",
        "plural": true,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "checkDetailsPageQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "checkDetailsPageQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "82250d0a8cd77a1f0761828de37770fe",
    "id": null,
    "metadata": {},
    "name": "checkDetailsPageQuery",
    "operationKind": "query",
    "text": "query checkDetailsPageQuery(\n  $id: ID!\n) {\n  schemaCheck(id: $id) {\n    id\n    createdAt\n    subgraphName\n    errorCount\n    validationCheckErrors {\n      title\n      message\n    }\n    compositionCheckErrors {\n      title\n      message\n    }\n    operationCheckErrors {\n      message\n      severity\n      title\n    }\n    lintCheckErrors {\n      message\n      severity\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "70644e0a86487dee5ef264be3a15eeea";

export default node;
