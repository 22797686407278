/**
 * @generated SignedSource<<4fff9d3934c806fd93f6217c450935c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type graphGitPageQuery$variables = {
  account: string;
  graph: string;
};
export type graphGitPageQuery$data = {
  readonly graph: {
    readonly branches: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly name: string;
        };
      }>;
    };
    readonly id: string;
    readonly productionBranch: {
      readonly name: string;
    };
    readonly repository: {
      readonly branches: ReadonlyArray<string>;
      readonly owner: string;
      readonly slug: string;
      readonly url: string;
    } | null | undefined;
  } | null | undefined;
};
export type graphGitPageQuery = {
  response: graphGitPageQuery$data;
  variables: graphGitPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GitRepository",
  "kind": "LinkedField",
  "name": "repository",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "branches",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphGitPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BranchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v4/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphGitPageQuery",
    "selections": [
      {
        "alias": "graph",
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "productionBranch",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BranchConnection",
            "kind": "LinkedField",
            "name": "branches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BranchEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Branch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67ab334d5cf6cd48b613d4a4d16d771e",
    "id": null,
    "metadata": {},
    "name": "graphGitPageQuery",
    "operationKind": "query",
    "text": "query graphGitPageQuery(\n  $account: String!\n  $graph: String!\n) {\n  graph: graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    id\n    productionBranch {\n      name\n      id\n    }\n    branches {\n      edges {\n        node {\n          name\n          id\n        }\n      }\n    }\n    repository {\n      url\n      slug\n      owner\n      branches\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a3f1a45fd96078b8d7d8a8f96ab0be62";

export default node;
