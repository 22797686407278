/**
 * @generated SignedSource<<e4a045893b16efa6f807e86bf0af7f0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personalGeneralSettingsPageQuery$variables = Record<PropertyKey, never>;
export type personalGeneralSettingsPageQuery$data = {
  readonly viewer: {
    readonly personalAccount: {
      readonly " $fragmentSpreads": FragmentRefs<"accountSlugSettingFragment">;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"accountNameSettingFragment">;
  } | null | undefined;
};
export type personalGeneralSettingsPageQuery = {
  response: personalGeneralSettingsPageQuery$data;
  variables: personalGeneralSettingsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalGeneralSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "accountNameSettingFragment"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "accountSlugSettingFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalGeneralSettingsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  }
                ],
                "type": "Account",
                "abstractKey": "__isAccount"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7df3de4fee1dba61558c1e11b32eacaf",
    "id": null,
    "metadata": {},
    "name": "personalGeneralSettingsPageQuery",
    "operationKind": "query",
    "text": "query personalGeneralSettingsPageQuery {\n  viewer {\n    ...accountNameSettingFragment\n    personalAccount {\n      ...accountSlugSettingFragment\n      id\n    }\n    id\n  }\n}\n\nfragment accountNameSettingFragment on User {\n  name\n}\n\nfragment accountSlugSettingFragment on Account {\n  __isAccount: __typename\n  slug\n}\n"
  }
};
})();

(node as any).hash = "905c4bfac5c0c50876a1022f8e597e9b";

export default node;
