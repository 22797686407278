import { lazy, useEffect } from 'react'

import { NotFoundPage } from '#lib/errors/errors'
import {
  Outlet,
  createRootRouteWithContext,
  useMatches,
} from '@tanstack/react-router'

interface Context {}

declare module '@tanstack/react-router' {
  interface StaticDataRouteOption {
    title: string
  }
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      )

export const Route = createRootRouteWithContext<Context>()({
  staticData: { title: 'Dashboard - Grafbase' },
  notFoundComponent: NotFoundPage,
  component: function RootRoute() {
    const match = useMatches()

    const title = match.at(-1)!.staticData.title

    useEffect(() => {
      document.title = `${title ?? 'Dashboard'} - Grafbase`
    }, [title])

    return (
      <>
        <Outlet />
        <TanStackRouterDevtools />
      </>
    )
  },
})
