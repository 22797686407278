import node, {
  type branchOverviewPageQuery,
} from '#gen/relay/branchOverviewPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'
import { formatISO } from 'date-fns'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/',
)({
  staticData: { title: 'Branch' },
  errorComponent: ErrorHandler,
  async loader({ context, params }) {
    const queryParams = {
      ...params,
      from: formatISO(new Date(Date.now() - 24 * 60 * 60 * 1_000)),
      to: formatISO(new Date()),
    } as const

    const { preload } = await context.preload<branchOverviewPageQuery>(
      node,
      queryParams,
    )

    return {
      preload,
      queryParams,
    }
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/branch-overview-page'),
    'BranchOverviewPage',
  ),
})
