import node, {
  type personalOverviewPageQuery,
} from '#gen/relay/personalOverviewPageQuery.graphql'
import { clearLastUsedAccount } from '#lib/account/account-persist'
import { getAccountRedirect } from '#lib/account/account-redirect'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { formatISO } from 'date-fns'
import { object, optional, parse, string } from 'valibot'

const SearchParamsSchema = object({
  filter: optional(string()),
})

export const Route = createFileRoute('/_dashboard/_personal/')({
  staticData: { title: 'Home' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },

  async beforeLoad({ context, location }) {
    if (!location.state.key) {
      const lastUsedAccount = await getAccountRedirect(context.relayEnvironment)

      if (lastUsedAccount) {
        throw redirect({
          replace: true,
          to: '/$account',
          params: { account: lastUsedAccount },
        })
      } else {
        clearLastUsedAccount()
      }
    }
  },
  loader({ context }) {
    return context.preload<personalOverviewPageQuery>(node, {
      from: formatISO(new Date(Date.now() - 24 * 60 * 60 * 1_000)),
      to: formatISO(new Date()),
    })
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account-overview/personal-overview-page'),
    'PersonalOverviewPage',
  ),
})
