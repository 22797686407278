import node, {
  type teamsTeamLayoutQuery,
} from '#gen/relay/teamsTeamLayoutQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/teams/$team/_team',
)({
  staticData: { title: 'Team' },
  loader({ context, params }) {
    return context.preload<teamsTeamLayoutQuery>(node, params)
  },
  component: lazyRouteComponent(
    () => import('#app/pages/account/teams/teams-page/teams-team-layout'),
    'TeamsTeamLayout',
  ),
})
