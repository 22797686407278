import { accountLayoutLoader } from '#app/pages/account/account-layout.loader'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/_account')({
  staticData: { title: 'Overview' },
  async loader({ context, params }) {
    return await accountLayoutLoader(context.preload, params.account)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/account/account-layout'),
    'AccountLayout',
  ),
})
