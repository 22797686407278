import node, {
  type teamsTeamSettingsPageQuery,
} from '#gen/relay/teamsTeamSettingsPageQuery.graphql'
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/_account/settings/teams/$team/_team/settings',
)({
  staticData: { title: 'Team Members' },
  loader({ context, params }) {
    return context.preload<teamsTeamSettingsPageQuery>(node, params)
  },
  component: lazyRouteComponent(
    () =>
      import('#app/pages/account/teams/teams-page/teams-team-settings-page'),
    'TeamsTeamSettingsPage',
  ),
})
