import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_dashboard/$account/_account/settings')({
  staticData: { title: 'Settings' },

  component: lazyLoadedComponent(
    () => import('#app/pages/settings/account-settings-layout'),
    'AccountSettingsLayout',
  ),
})
