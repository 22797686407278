/**
 * @generated SignedSource<<5c76e2f9632845c60ea298864be67cfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GraphMode = "MANAGED" | "SELF_HOSTED" | "%future added value";
export type GraphType = "FEDERATED" | "STANDALONE" | "%future added value";
export type branchLayoutQuery$variables = {
  account: string;
  graph: string;
};
export type branchLayoutQuery$data = {
  readonly graphByAccountSlug: {
    readonly graphMode: GraphMode;
    readonly graphType: GraphType | null | undefined;
  } | null | undefined;
};
export type branchLayoutQuery = {
  response: branchLayoutQuery$data;
  variables: branchLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "graph"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graphType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "graphMode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "branchLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "branchLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Graph",
        "kind": "LinkedField",
        "name": "graphByAccountSlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "830a6f83cd391712128d2615d7822a14",
    "id": null,
    "metadata": {},
    "name": "branchLayoutQuery",
    "operationKind": "query",
    "text": "query branchLayoutQuery(\n  $account: String!\n  $graph: String!\n) {\n  graphByAccountSlug(accountSlug: $account, graphSlug: $graph) {\n    graphType\n    graphMode\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "623350173ddf4f4e200e23e7f9befcca";

export default node;
