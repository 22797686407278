import { type DashboardNewGraphRedirectQuery } from '#gen/relay/DashboardNewGraphRedirectQuery.graphql'
import { createFileRoute, redirect } from '@tanstack/react-router'
import { graphql } from 'relay-runtime'
import { object, optional, parse, string } from 'valibot'

export const SearchParamsSchema = object({
  source: optional(string()),
  template: optional(string()),
  env: optional(string()),
})

export const Route = createFileRoute('/_dashboard/new/')({
  staticData: { title: 'New graph redirect' },
  validateSearch(search) {
    return parse(SearchParamsSchema, search)
  },
  loaderDeps(opts) {
    return {
      search: opts.search,
    }
  },
  async beforeLoad({ context, search }) {
    const res = await context.preload<DashboardNewGraphRedirectQuery>(graphql`
      query DashboardNewGraphRedirectQuery {
        viewer {
          personalAccount {
            slug
          }
        }
      }
    `)

    const { viewer } = await res.data

    if (viewer?.personalAccount?.slug) {
      throw redirect({
        to: '/new/$account',
        params: { account: viewer.personalAccount.slug },

        search: {
          source: search.source || undefined,
          template: search.template || undefined,
          env: search.env || undefined,
        },
      })
    }
  },
})
