import { StrictMode } from 'react'

import { ThemeProvider } from '#lib/theme/theme-context'
import { colorTheme } from '#lib/theme/theme.css'
import { Toaster } from '#lib/toast/toast'
import { RouterProvider } from '@tanstack/react-router'
// Polyfill Promise.withResolvers() until support has increased https://caniuse.com/mdn-javascript_builtins_promise_withresolvers
import '@ungap/with-resolvers'
import ReactDOM from 'react-dom/client'

import { Config } from './config'
import './global-theme.css'
import './global.css'
import { router } from './router'
import {
  ThirdPartyTools,
  setupThirdPartyTools,
} from './third-party/third-party-tools'

if (Config.IS_MANAGED_BUILD) {
  setupThirdPartyTools()
}

if (typeof document !== 'undefined') {
  document.body.classList.add(colorTheme)
}

const rootElement = document.getElementById('root')

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<App />)
}

function App() {
  return (
    <StrictMode>
      <ThemeProvider>
        <RouterProvider router={router} />

        <Toaster />
      </ThemeProvider>

      {Config.IS_MANAGED_BUILD && <ThirdPartyTools />}
    </StrictMode>
  )
}

if (import.meta.env.DEV) {
  // Override console.error
  // This is a hack to suppress the warning about missing defaultProps in recharts library as of version 2.12.2
  // @link https://github.com/recharts/recharts/issues/3615
  // @link https://github.com/recharts/recharts/issues/4558
  const error = console.error
  console.error = (...args: any) => {
    if (/defaultProps/.test(args[0])) return
    error(...args)
  }
}
