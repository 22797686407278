import node, {
  type newSelfHostedGraphPageQuery,
} from '#gen/relay/newSelfHostedGraphPageQuery.graphql'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/new/$account/self-hosted-graph',
)({
  staticData: { title: 'Create self-hosted graph' },
  async loader({ context, params }) {
    return context.preload<newSelfHostedGraphPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/graph/new-graph/new-self-hosted-graph-page'),
    'NewSelfHostedGraphPage',
  ),
})
