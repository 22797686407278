/**
 * @generated SignedSource<<1839524f35dc15e16d41bfac2bdbb702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type personalAccessTokensPageQuery$variables = Record<PropertyKey, never>;
export type personalAccessTokensPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"accessTokensPageFragment" | "accessTokensPageQueryFragment">;
};
export type personalAccessTokensPageQuery = {
  response: personalAccessTokensPageQuery$data;
  variables: personalAccessTokensPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "personalAccessTokensPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accessTokensPageFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "accessTokensPageQueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "personalAccessTokensPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PersonalAccount",
            "kind": "LinkedField",
            "name": "personalAccount",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConnection",
            "kind": "LinkedField",
            "name": "organizations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AccessTokenConnection",
        "kind": "LinkedField",
        "name": "accessTokens",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AccessTokenEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessToken",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Graph",
                    "kind": "LinkedField",
                    "name": "graphScopes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "graphType",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "accessTokens(first:1000)"
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "connection",
        "key": "accessTokensPageQueryFragment_accessTokens",
        "kind": "LinkedHandle",
        "name": "accessTokens"
      }
    ]
  },
  "params": {
    "cacheID": "7a629d9673d1b4e54a9f188fa4b1ad88",
    "id": null,
    "metadata": {},
    "name": "personalAccessTokensPageQuery",
    "operationKind": "query",
    "text": "query personalAccessTokensPageQuery {\n  ...accessTokensPageFragment\n  ...accessTokensPageQueryFragment\n}\n\nfragment accessTokensPageFragment on Query {\n  viewer {\n    ...tokenFormDetails\n    personalAccount {\n      id\n      slug\n      name\n    }\n    organizations {\n      nodes {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n\nfragment accessTokensPageQueryFragment on Query {\n  accessTokens(first: 1000) {\n    edges {\n      node {\n        id\n        createdAt\n        ...tokenRowDetails\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment tokenFormDetails on User {\n  personalAccount {\n    id\n    name\n  }\n  organizations {\n    nodes {\n      id\n      name\n    }\n  }\n}\n\nfragment tokenRowDetails on AccessToken {\n  id\n  name\n  accountId\n  createdAt\n  graphScopes {\n    graphType\n    slug\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "348c7e3f6353172ec82303742308fc2e";

export default node;
