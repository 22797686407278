import type { accountLayoutQuery as accountLayoutQueryType } from '#gen/relay/accountLayoutQuery.graphql'
import type { RelayPreloader } from '#lib/relay/relay-preloader'
import { redirect } from '@tanstack/react-router'
import { graphql } from 'react-relay'

export const accountLayoutQuery = graphql`
  query accountLayoutQuery {
    viewer @required(action: THROW) {
      personalAccount {
        slug
      }

      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...selectsDataWrapperAccountsFragment
    }

    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...accountPermissionsFragment
  }
`

export async function accountLayoutLoader(
  preload: RelayPreloader,
  account: string,
) {
  const res = await preload<accountLayoutQueryType>(accountLayoutQuery)

  // Redirect to the dashboard route if the account slug matches the personal
  // account. This avoids a bunch of issues with org specific pages loading
  // data from a personal account.
  const { viewer } = await res.data

  if (account === viewer?.personalAccount?.slug) {
    throw redirect({ to: '/' })
  }

  return res
}
