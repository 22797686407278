import node, {
  type federatedSchemaPageQuery,
} from '#gen/relay/federatedSchemaPageQuery.graphql'
import { ErrorHandler } from '#lib/errors/errors'
import { lazyLoadedComponent } from '#lib/routing/lazy-loaded-component'
import '@pathfinder-ide/react/dist/style.css'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_dashboard/$account/$graph/branches/$branch/schema/sdl/federated-schema',
)({
  staticData: { title: 'SDL - Federated Schema' },
  errorComponent: ErrorHandler,
  loader({ context, params }) {
    return context.preload<federatedSchemaPageQuery>(node, params)
  },
  component: lazyLoadedComponent(
    () => import('#app/pages/branch/schema/federated-schema-page'),
    'FederatedSchemaPage',
  ),
})
