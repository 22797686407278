import { subDays, subHours, subMinutes, subWeeks } from 'date-fns'

import { type TimeRangeUnit } from './time-range-types'

const timeRangeUnits: TimeRangeUnit[] = ['m', 'h', 'd', 'w']

export class InvalidTimeRangeCodeError extends Error {}

export function parseTimeRangeShortCode(code: string): {
  value: number
  unit: TimeRangeUnit
} {
  const unit = code.slice(-1) as TimeRangeUnit
  const value = Number(code.slice(0, -1))

  if (!timeRangeUnits.includes(unit))
    throw new InvalidTimeRangeCodeError(
      `Time range code does not have a valid unit`,
    )

  if (isNaN(value))
    throw new InvalidTimeRangeCodeError(
      `Time range code does not have a valid value`,
    )

  return { value, unit }
}

export class InvalidTimeRangeError extends Error {}

export function parseTimeRange(
  range: string,
  fromDateString?: string,
  toDateString?: string,
): { from: Date; to: Date } {
  let from: Date
  let to: Date

  if (range === 'custom') {
    if (!fromDateString || !toDateString)
      throw new InvalidTimeRangeError('Custom range requires from and to')

    if (isNaN(Date.parse(fromDateString)))
      throw new InvalidTimeRangeError(
        `Provided 'from' date "${fromDateString}" is invalid`,
      )

    if (isNaN(Date.parse(toDateString)))
      throw new InvalidTimeRangeError(
        `Provided 'from' date "${toDateString}" is invalid`,
      )

    from = new Date(fromDateString)
    to = new Date(toDateString)
  } else {
    const { value, unit } = parseTimeRangeShortCode(range)

    to = new Date()

    switch (unit) {
      case 'm':
        from = subMinutes(to, value)
        break
      case 'h':
        from = subHours(to, value)
        break
      case 'd':
        from = subDays(to, value)
        break
      case 'w':
        from = subWeeks(to, value)
        break
    }
  }

  return { from, to }
}
