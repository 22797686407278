import { Config } from '#app/config'
import { captureException } from '@sentry/react'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

export function createRelayEnvironment(getToken: () => Promise<string | null>) {
  const network = Network.create(async (request, variables) => {
    const token = await getToken()

    if (!token) throw new Error('Missing authentication token')

    const headers = new Headers()
    headers.append('Authorization', `Bearer ${token}`)

    const featureFlags = localStorage.getItem('feature-flags')
    if (featureFlags) headers.append('X-Feature-Flags', featureFlags)

    try {
      const resp = await fetch(Config.GRAFBASE_GRAPHQL_API_URL, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          query: request.text,
          variables,
        }),
        signal:
          request.operationKind === 'query'
            ? AbortSignal.timeout(15_000)
            : undefined,
      })

      if (!resp.ok) {
        const error = new Error(
          `Network error ${resp.status} ${resp.statusText}`,
        )
        captureException(error)
        throw error
      }

      const json = await resp.json()

      // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
      // property of the response. If any exceptions occurred when processing the request,
      // throw an error to indicate to the developer what went wrong.
      if (Array.isArray(json.errors)) {
        captureException(json.errors)

        console.error(
          `Error fetching GraphQL query '${
            request.name
          }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
            json.errors,
          )}`,
        )
      }

      return json
    } catch (error) {
      if (error instanceof Error && error.name === 'TimeoutError') {
        throw new Error('API request timed out')
      }

      throw new Error(`Network error`, { cause: error })
    }
  })

  const store = new Store(RecordSource.create())

  return new Environment({ store, network })
}
