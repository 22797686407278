import { TOKEN_COOKIE_NAME } from './constants'
import { getCookieValue } from './cookie'
import { isJwtValid, parseJwt } from './jwt'
import { type JWT } from './types'

function getAuthInfo() {
  const token = getCookieValue(TOKEN_COOKIE_NAME)
  if (!token) return null

  const jwt: JWT = parseJwt(token)
  let renewAt = Infinity

  // Refresh token 5s before expiry, instead of waiting until it has fully
  // expired. This increases the chance of the token being refreshed during an
  // interval check, instead of in the hot path of an API request, which would
  // be slowed down by having to wait for token refresh.
  if (jwt.exp) renewAt = jwt.exp * 1000 - 5000

  return { token, jwt, refreshAt: renewAt }
}

export class AuthClient {
  #auth = getAuthInfo()
  #checkInterval: ReturnType<typeof setInterval> | number = 0

  #checkAndRefreshPromise: Promise<void> | null = null

  constructor(private refreshOnInterval: boolean) {
    if (refreshOnInterval) {
      this.#checkInterval = setInterval(
        this.#checkAndRefreshAuthInfo.bind(this),
        1000,
      )
    }
  }

  async #checkAndRefreshAuthInfo() {
    if (!this.refreshOnInterval) {
      clearInterval(this.#checkInterval)
      return
    }

    if (this.#auth && this.#auth.refreshAt > Date.now()) return

    // If we're already refreshing, don't start another refresh.
    if (this.#checkAndRefreshPromise) return this.#checkAndRefreshPromise

    const { promise, resolve } = Promise.withResolvers<void>()

    this.#checkAndRefreshPromise = promise

    try {
      const response = await fetch('/_auth/refresh')

      // Cookie with access token is refreshed automatically server side on
      // refresh request. Update cached values with the new token and jwt.
      this.#auth = getAuthInfo()

      if (!response.ok || !this.hasValidToken())
        throw new Error('Failed to refresh access token')
    } catch {
      this.#auth = null

      // Stop polling for a new token if we fail to refresh. It means the
      // session is dead and we need to send the user through the sign-in flow again.
      if (this.#checkInterval) clearInterval(this.#checkInterval)
    } finally {
      this.#checkAndRefreshPromise = null
      resolve()
    }

    return promise
  }

  hasValidToken(): boolean {
    if (!this.#auth?.jwt) return false
    return isJwtValid(this.#auth.jwt)
  }

  /** Checks if the user could potentially be signed in. If the token has
   * expired the user could still be signed in, but we won't know until we try
   * to refresh the token. The upside to using this instead of `isSignedIn` is
   * that it is sync. */
  potentiallySignedIn(): boolean {
    return Boolean(this.#auth?.token)
  }

  async isSignedIn(): Promise<boolean> {
    await this.#checkAndRefreshAuthInfo()
    return this.hasValidToken()
  }

  async getToken(): Promise<string | null> {
    await this.#checkAndRefreshAuthInfo()
    return this.#auth?.token ?? null
  }
}
