/**
 * @generated SignedSource<<ef1f7482a678298844025e79509f3ee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type changelogPageQuery$variables = {
  account: string;
  branch: string;
  count: number;
  graph: string;
};
export type changelogPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"changelogPagePaginationFragment">;
};
export type changelogPageQuery = {
  response: changelogPageQuery$data;
  variables: changelogPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "account"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "branch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "graph"
},
v4 = [
  {
    "kind": "Variable",
    "name": "accountSlug",
    "variableName": "account"
  },
  {
    "kind": "Variable",
    "name": "branch",
    "variableName": "branch"
  },
  {
    "kind": "Variable",
    "name": "graphSlug",
    "variableName": "graph"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "changelogPageQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          }
        ],
        "kind": "FragmentSpread",
        "name": "changelogPagePaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "changelogPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SchemaVersionConnection",
        "kind": "LinkedField",
        "name": "schemaVersions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchemaVersionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchemaVersion",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaVersionDelta",
                    "kind": "LinkedField",
                    "name": "delta",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "added",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "removed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "modified",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchemaVersionChangeConnection",
                    "kind": "LinkedField",
                    "name": "changes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchemaVersionChange",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "accountSlug",
          "graphSlug",
          "branch"
        ],
        "handle": "connection",
        "key": "ChangelogPageFragment_schemaVersions",
        "kind": "LinkedHandle",
        "name": "schemaVersions"
      }
    ]
  },
  "params": {
    "cacheID": "ade692df91494eb684b29283212f9c2d",
    "id": null,
    "metadata": {},
    "name": "changelogPageQuery",
    "operationKind": "query",
    "text": "query changelogPageQuery(\n  $account: String!\n  $graph: String!\n  $branch: String!\n  $count: Int!\n) {\n  ...changelogPagePaginationFragment_yu5n1\n}\n\nfragment changelogPagePaginationFragment_yu5n1 on Query {\n  schemaVersions(accountSlug: $account, graphSlug: $graph, branch: $branch, last: $count) {\n    edges {\n      node {\n        id\n        ...changelogVersionFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasPreviousPage\n      startCursor\n    }\n  }\n}\n\nfragment changelogVersionFragment on SchemaVersion {\n  id\n  createdAt\n  delta {\n    added\n    removed\n    modified\n  }\n  changes {\n    nodes {\n      type\n      path\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5e3d6aa478706e3f5968b8ab2ea0e026";

export default node;
