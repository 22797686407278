/**
 * @generated SignedSource<<e213c26c4ab68a136bc83a7aec663155>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OrbInvoiceStatus = "DRAFT" | "ISSUED" | "PAID" | "SYNCED" | "VOID" | "%future added value";
export type invoicesPageQuery$variables = {
  account: string;
};
export type invoicesPageQuery$data = {
  readonly accountBySlug: {
    readonly orbCustomer?: {
      readonly subscriptions: {
        readonly data: ReadonlyArray<{
          readonly invoices: {
            readonly data: ReadonlyArray<{
              readonly currency: string;
              readonly invoiceDate: string;
              readonly invoiceNumber: string;
              readonly invoicePdf: any;
              readonly status: OrbInvoiceStatus;
              readonly total: string;
            }>;
          };
        }>;
      };
    } | null | undefined;
  } | null | undefined;
};
export type invoicesPageQuery = {
  response: invoicesPageQuery$data;
  variables: invoicesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "account"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "account"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrbCustomer",
      "kind": "LinkedField",
      "name": "orbCustomer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrbPaginatedSubscriptions",
          "kind": "LinkedField",
          "name": "subscriptions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrbSubscription",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrbPaginatedInvoices",
                  "kind": "LinkedField",
                  "name": "invoices",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrbInvoice",
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "currency",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "total",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoiceDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoiceNumber",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoicePdf",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "invoicesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "invoicesPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "accountBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9fab61bad3d764dc821c675da9e859d9",
    "id": null,
    "metadata": {},
    "name": "invoicesPageQuery",
    "operationKind": "query",
    "text": "query invoicesPageQuery(\n  $account: String!\n) {\n  accountBySlug(slug: $account) {\n    __typename\n    ... on Organization {\n      orbCustomer {\n        subscriptions {\n          data {\n            invoices {\n              data {\n                currency\n                total\n                status\n                invoiceDate\n                invoiceNumber\n                invoicePdf\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a486898968737b0a44f6ee78992fc74";

export default node;
