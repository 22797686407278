import { forwardRef } from 'react'

import type { ExtractVariant } from '#lib/types/theme.types'
import clsx from 'clsx'

import { Spinner } from '../loaders/spinner'
import { Slot } from '../slot/slot'
import { ButtonStyles as styles } from './button.css'

type Props = {
  isLoading?: boolean
  variant: ExtractVariant<typeof styles.button, 'variant'>
  size?: ExtractVariant<typeof styles.button, 'size'>
  fullWidth?: boolean
} & AsChildProps<JSX.IntrinsicElements['button']>

type AsChildProps<DefaultElementProps> =
  | ({ asChild?: false } & DefaultElementProps)
  | { asChild: true; children: React.ReactNode }

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ isLoading, variant, size, fullWidth, children, ...props }, ref) => {
    if (props.asChild)
      return (
        <Slot
          ref={ref}
          className={styles.button({ variant, size, fullWidth })}
          {...props}
        >
          {children}
        </Slot>
      )

    return (
      <button
        {...props}
        ref={ref}
        className={clsx(
          styles.button({ variant, size, fullWidth }),
          props.className,
        )}
        data-loading={isLoading ? '' : undefined}
        disabled={props.disabled || isLoading}
      >
        <span className={styles.buttonText}>{children}</span>

        <Spinner size="1rem" className={styles.spinner} />
      </button>
    )
  },
)

Button.displayName = 'Button'
