import { Toaster as ArkToaster, Toast, createToaster } from '@ark-ui/react'

import { Icon } from '../icon/icon'
import { Spinner } from '../ui/loaders/spinner'
import { ToastStyles as styles } from './toast.css'

const toaster = createToaster({
  placement: 'bottom',
  duration: 5_000,
})

function Toaster() {
  return (
    <ArkToaster toaster={toaster}>
      {toast => (
        <Toast.Root className={styles.container}>
          <div className={styles.outerTitleContainer}>
            <span className={styles.innerTitleContainer}>
              {/* success icon has a background already, so doesn't need a colored circle behind it */}
              {toast.type === 'loading' && <Spinner size={16} />}
              {toast.type === 'success' && (
                <Icon icon="success" size="sm" className={styles.successIcon} />
              )}

              {toast.type === 'error' && (
                <span
                  className={styles.icon({
                    type:
                      toast.type === 'success'
                        ? 'success'
                        : toast.type === 'error'
                          ? 'error'
                          : undefined,
                  })}
                >
                  <Icon icon="close" size="xs" />
                </span>
              )}

              <Toast.Title>{toast.title}</Toast.Title>
            </span>

            <Toast.CloseTrigger className={styles.closeButton}>
              <Icon icon="close" size="sm" />
            </Toast.CloseTrigger>
          </div>

          {toast.description && (
            <Toast.Description>{toast.description}</Toast.Description>
          )}
        </Toast.Root>
      )}
    </ArkToaster>
  )
}

export { Toaster, toaster as toast }
