/* eslint-disable */
// @ts-nocheck

export function loadKoala() {
  if (window.ko) return
  ;(window.ko = []),
    [
      'identify',
      'track',
      'removeListeners',
      'open',
      'on',
      'off',
      'qualify',
      'ready',
    ].forEach(function (t) {
      ko[t] = function () {
        const n = [].slice.call(arguments)
        return n.unshift(t), ko.push(n), ko
      }
    })
  const n = document.createElement('script')
  ;(n.async = !0),
    n.setAttribute(
      'src',
      'https://cdn.getkoala.com/v1/pk_a99db261bb24515d8c2db567e85d0aa9a3f7/sdk.js',
    ),
    (document.body || document.head).appendChild(n)
}
